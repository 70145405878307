import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Resources from "../components/projects/Resources"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        resources: Array<{
          title: string
          description: string
          url: string
          image: string
          video?: boolean
        }>
        features: Array<{
          title: string
          description: string
        }>
      }
    }
  }
}

// markup
const ServerlessTemplate = (props: Props) => {
  const resources = props.data.page.frontmatter.resources
  const features = props.data.page.frontmatter.features
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={`serverless`}
    >
      <div className="extra-0">
        <img src={`/img/extra/serverless-8.svg`} alt="" />
      </div>
      <div className="container">
        <div className="hero-serverless content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown"}
          ></HTMLContent>
          <img
            className="image img-light"
            src={`/img/extra/serverless-4.svg`}
            alt=""
          />
          <img
            className="image img-dark"
            src={`/img/extra/serverless-4-dark.svg`}
            alt=""
          />
        </div>

        <div className="markdown links-animate content">
          <article className="columns has-big-border-dark-top pt-6 pb-5">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[0].title}
              </h1>
            </div>
            <div className="column is-8 pb-4">
              <div className="pt-2 pb-5">
                <div className="has-background-light has-text-centered p-6">
                  <img
                    className="img-light"
                    width={500}
                    src={`/img/extra/serverless-5.svg`}
                    alt="Diagram"
                  />
                  <img
                    className="img-dark"
                    width={500}
                    src={`/img/extra/serverless-5-dark.svg`}
                    alt="Diagram"
                  />
                  <div className="columns has-text-left pt-6">
                    <div className="column is-6">
                      <h2 className="m-0 p-0 is-size-4">
                        Function as a Service (FaaS)
                      </h2>
                      <ul>
                        <li>Event-driven execution.</li>
                        <li>
                          Developers delegate all server-specific tasks to the
                          FaaS platform.
                        </li>
                        <li>
                          Developers only write business logic that is invoked
                          by the platform, allowing for a more resilient
                          requirement evolution as business needs change.
                        </li>
                      </ul>
                    </div>
                    <div className="column is-6">
                      <h2 className="m-0 p-0 is-size-4">
                        Traditional applications
                      </h2>
                      <ul>
                        <li>
                          Must maintain server infrastructure (installing,
                          configuring, patching, upgrading, etc.).
                        </li>
                        <li>
                          Infrastructure scales in ways that might not be
                          dynamic enough for the workload (wasting resources).
                        </li>
                        <li>
                          Developers write integration code to deal with
                          messaging platforms, HTTP request/responses, etc.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[1].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent
                className=""
                content={features[1].description}
              ></HTMLContent>
              <div className="pt-5">
                <Link to="/guides/gs/service-registration-and-discovery/">
                  Get started with this simple guide
                </Link>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top pt-6 pb-2">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[2].title}
              </h1>
            </div>
            <div className="column is-8 pl-5">
              <HTMLContent
                className=""
                content={features[2].description}
              ></HTMLContent>
            </div>
          </article>
        </div>

        <div className="has-background-light mt-6 p-6 has-text-centered">
          <div className="py-5">
            <h2 className="is-size-2 is-size-3-mobile has-text-weight-medium pb-6">
              Ready to get started?
            </h2>
            <Link
              className="button is-spring"
              to={`/2019/10/15/simple-event-driven-microservices-with-spring-cloud-stream`}
            >
              TRY THIS TUTORIAL
            </Link>
          </div>
        </div>

        <Resources resources={resources} />
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default ServerlessTemplate

// graphQL queries
export const pageQuery = graphql`
  query ServerlessTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
        resources {
          title
          description
          image
          url
          video
        }
        features {
          title
          description
        }
      }
    }
  }
`
